import { FC } from "react";

import { Trans } from "next-i18next";

type Props = {
  translation_key: string;
  parent?: string;
  components?: NodeJS.Dict<JSX.Element | string>;
};

const Title: FC<Props> = ({ translation_key, parent = null, components = null }) => {
  const _components = Object.assign(
    {},
    {
      blue: <strong key={0} className={"color-one"}></strong>,
      orange: <strong key={1} className={"color-two"}></strong>,
    },
    components
  );

  return <Trans className="homepage-titles" i18nKey={translation_key} components={_components} parent={parent} />;
};
export default Title;
