import { FC, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";
import { Form, Spinner } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { loadTranslations } from "@lib/i18n";

import { GeolocateMe, Location } from "@entities/location";

type Props = {
  selectedLocation: Location | GeolocateMe;
  setSelectedLocation: (loc: Location | GeolocateMe) => void;
  geolocateMeActivated: boolean;
  setGeolocateMeActivated: (activated: boolean) => void;
};
/**
 * A component that allows you to either geolocate you
 * @returns
 */
const FormCheckGeolocateMe: FC<Props> = ({
  selectedLocation,
  setSelectedLocation,
  geolocateMeActivated,
  setGeolocateMeActivated,
}) => {
  const { t } = useTranslation("homepage_components");
  loadTranslations("homepage_components");

  const { setValue } = useFormContext();

  const [previouslySelectedCity, setPreviouslySelectedCity] = useState<Location>();
  const [geolocateMeLoading, setGeolocateMeLoading] = useState(false);
  const [locationError, setLocationError] = useState("");

  useEffect(() => {
    if (selectedLocation instanceof GeolocateMe) {
      setGeolocateMeActivated(true);
    } else {
      setPreviouslySelectedCity(selectedLocation);
    }
  }, [selectedLocation, setGeolocateMeActivated, setPreviouslySelectedCity]);

  function onDisablingGeoLocation() {
    setGeolocateMeLoading(false);
    setGeolocateMeActivated(false);
    setValue("geolocateMe", false);
    setSelectedLocation(previouslySelectedCity);
  }

  return (
    <>
      <Form.Check
        id="geolocate_me"
        type="switch"
        label={
          geolocateMeLoading ? (
            <Spinner animation="border" style={{ height: 20, width: 20 }}>
              <span className="sr-only">{t("homepage_components:loading")}</span>
            </Spinner>
          ) : (
            <>{t("geolocate_me")}</>
          )
        }
        checked={geolocateMeActivated}
        onChange={(e) => {
          if (e.target.checked && (!selectedLocation || selectedLocation instanceof Location)) {
            setPreviouslySelectedCity(selectedLocation as Location);
            setGeolocateMeLoading(true);

            navigator.geolocation.getCurrentPosition(
              function (position) {
                setGeolocateMeLoading(false);
                setGeolocateMeActivated(true);
                setValue("geolocateMe", true);
                setLocationError("");
                setSelectedLocation(
                  new GeolocateMe(
                    {
                      lat: position.coords.latitude,
                      lon: position.coords.longitude,
                    },
                    t("my_location")
                  )
                );
              },
              function (err) {
                setLocationError(err.message);
                onDisablingGeoLocation();
              }
            );
          } else {
            onDisablingGeoLocation();
          }
        }}
      />
      {locationError && <Form.Control.Feedback type="invalid">{locationError}</Form.Control.Feedback>}
    </>
  );
};

export default FormCheckGeolocateMe;
