import { FC, useEffect, useRef, useState } from "react";

import { useTranslation } from "next-i18next";
import Autosuggest from "react-autosuggest";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { searchCitiesAndStates } from "@lib/fetches";
import { loadTranslations } from "@lib/i18n";

import { Location } from "@entities/location";

type Props = {
  selectedCountrySlug: string;
  selectedLocationName: string;
  onSuggestionSelectedCallback: (_: Location) => void;
  disabled: boolean;
};
/**
 * A component responsible for rendering a list of cities and states that can be searched
 * and auto-suggested. It's basically an input that auto-suggest results.
 * Once a result is selected, it calls the `onSuggestionSelectedCallback` and let the parent do the updates.
 *
 * @param selectedCountrySlug: the selected country, to limit search
 * @param selectedLocationName: the current selected location. It's mostly for when we load the page
 * @param onSuggestionSelectedCallback: the callback function to call when a user selects a suggestion
 * @param disabled: boolean to disable the input
 */
const CitiesAutoSuggest: FC<Props> = ({
  selectedCountrySlug,
  selectedLocationName,
  onSuggestionSelectedCallback,
  disabled,
}) => {
  const { t } = useTranslation(["search_engine_results", "error_message"]);
  loadTranslations("search_engine_results");
  loadTranslations("error_message");

  const {
    register,
    setValue: setFormValue,
    formState: { errors },
  } = useFormContext();
  const locationField = register("location", { disabled: disabled });

  const timer = useRef(null);
  const [suggestions, setSuggestions] = useState<Array<Location>>([]);
  const [searchValue, setSearchValue] = useState("");

  /**
   * Keep both the state value and form (from the parent) value aligned.
   * It's important because if we reset the selected state value here, we also
   * want to reset it in the form to apply the correct validation.
   * Same when you set the value.
   * @param locationName
   */
  function setStateAndFormValue(locationName: string) {
    setSearchValue(locationName);
    setFormValue("location", locationName);
  }

  useEffect(() => {
    // no need to update if we already have this value
    if (selectedLocationName === searchValue) {
      return;
    }
    // we always want to have a string as value because else Autosuggest crashes.
    if (selectedLocationName === null || selectedLocationName == undefined) {
      setSearchValue("");
      return;
    }

    setStateAndFormValue(selectedLocationName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationName]);

  /**
   * Tell auto-suggest how to match users'inputs to possible values.
   *
   * Currently, for each input, we iterate over the list of all possible
   * city and states and we see if the name match.
   *
   * @param value
   * @returns
   */
  async function searchSuggestions(value: string) {
    return await searchCitiesAndStates(selectedCountrySlug, value);
  }

  /**
   * Tell the parent when we've selected a new location
   *
   * @param event
   * @param suggestion: the suggestion (Location) selection
   */
  function onSuggestionSelected(event, { suggestion }) {
    onSuggestionSelectedCallback(suggestion);
    setStateAndFormValue(suggestion.getLocationStr());
  }

  /**
   * Called by the component when the input is changes, to give us the
   * opportunity to update the suggestion list.
   *
   * If we detect that the input is empty, we also tell that to the parent.
   *
   * @param value: the input value
   */
  function onSuggestionsFetchRequested({ value }: { value: string }) {
    clearTimeout(timer.current);

    // if the user hasn't input enough, we show nothing
    if (value === "" || value === undefined || value.length < 3) {
      onSuggestionSelectedCallback(null);
      setSuggestions([]);
    } else {
      // we are going to do an http query, so we start by showing that we
      // are searching
      setSuggestions([new Location({ real_name: t("search_engine_results:searching") })]);

      // then we start a timeout for debounce.
      timer.current = setTimeout(() => {
        // query the server for search and set it to be printed as suggestions.
        searchSuggestions(value)
          .then((data) => {
            setSuggestions(data);
          })
          .catch(() => {
            setSuggestions([]);
          });
      }, 500);
    }
  }

  /**
   * Return the location as a string.
   *   - if it's a city, we show:  city (state)
   *   - if it's a state, we show: state
   *
   * @param location
   * @returns
   */
  function getLocationStr(location: Location): string {
    return location.getLocationStr();
  }

  /**
   * Render the suggestion
   */
  function renderSuggestion(suggestion: Location) {
    return <div>{getLocationStr(suggestion)}</div>;
  }

  /**
   * On value change, we update our input state variable.
   *
   * Without that, Autosuggest won't print the user's input,
   * so he can type, but he won't see what he type.
   *
   * @param event: typing event, we ignore
   * @param newValue: new input form user
   */
  function onInputChange(event) {
    let value = event.target.value;
    if (!value) {
      value = "";
    }
    setSearchValue(value);
  }

  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        shouldRenderSuggestions={() => true}
        getSuggestionValue={getLocationStr}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: `${t("search_engine_results:search_location")}`,
          ...locationField,
          // override the value and onChange of the locationField,
          // because we need a state variable to Autosuggest.
          value: searchValue,
          onChange: onInputChange,
        }}
        highlightFirstSuggestion={true}
      />
      {errors["location"] && (
        <Form.Control.Feedback type="invalid">{t("error_message:empty_field_error")}</Form.Control.Feedback>
      )}
    </>
  );
};

export default CitiesAutoSuggest;
