import { FC, useContext } from "react";

import { AccordionContext } from "react-bootstrap";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  eventKey: string;
};
const ContextAwareToggle: FC<Props> = ({ eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  return <FontAwesomeIcon className="accordion_chevron" icon={isCurrentEventKey ? faChevronUp : faChevronDown} />;
};

export default ContextAwareToggle;
