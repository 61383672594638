import { useRouter } from "next/router";

import { FC, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";
import { Col, NavDropdown, Row } from "react-bootstrap";

import ContextAwareToggle from "@components/chevron";

import { countriesFlags } from "@lib/constants";
import { fetchCountries, fetchUserCountry } from "@lib/fetches";
import { loadTranslations } from "@lib/i18n";
import { QueryParamsToString } from "@lib/utils";

import { GeolocateMe, Location } from "@entities/location";

import CitiesAutoSuggest from "./cities_auto_suggest";

type Props = {
  selectedLocation: Location | GeolocateMe;
  geolocateMeActivated?: boolean;
  setSelectedLocation: (loc: Location | GeolocateMe) => void;
  onCountryChanged?: (country: string) => void;
};
/**
 * A component that allows you to either geolocate you OR
 * search for a location for a given country.
 * For both, we'll call the `setXX` functions once a choice is made.
 *
 * It leverages our location API to get the list of country and location.
 * Each time the country is changed, we re-do a query to get the list of all
 * associated location.
 *
 * @param onCountryChanged: the callback function to call when a user selects a country
 * @param selectedLocation: the current selected location
 * @param setSelectedLocation: called when we've selected a new location
 * @param geolocateMeActivated
 * @returns
 */
const CountryAndCitySearch: FC<Props> = ({
  selectedLocation,
  setSelectedLocation,
  onCountryChanged,
  geolocateMeActivated,
}) => {
  const { t } = useTranslation("homepage_components");
  loadTranslations("homepage_components");

  const router = useRouter();

  // selectedCountrySlug is the country that the user can choose to then filter
  // the selectedLocation
  const [selectedCountrySlug, setSelectedCountrySlug] = useState<string>(null);
  // list of available countries
  const [countries, setCountries] = useState<NodeJS.Dict<Location>>(null);
  // true if the user wants to be geolocalized.

  /**
   * a small function do factorize what we need to do when a new country
   * gets selected. In effect, we change the country state and then call the
   * call back function given by the parent.
   * @param newSlug
   */
  function updateSelectedCountry(newSlug) {
    setSelectedCountrySlug(newSlug);
    onCountryChanged && onCountryChanged(newSlug);
  }

  // Load the list of countries when the page loads.
  useEffect(() => {
    async function fn() {
      const { country_slug } = QueryParamsToString(router.query);

      const [apiCountry, userLocation] = await Promise.all([fetchCountries(), fetchUserCountry()]);
      setCountries(apiCountry);

      // only update the selected country if we have no selected location
      // (when we load a search result page for instance)
      // in the case were we do have a selectedLocation, then we'll call
      // updateSelectedCountry in it's own useEffect.
      if (!(selectedLocation && selectedLocation instanceof Location)) {
        // for the basic home page, it's possible that we have a country_slug
        // in the url. If we do and it's a valid one, we select this.
        // else, we fall back to the user's ip's country.
        updateSelectedCountry(country_slug && country_slug in apiCountry ? country_slug : userLocation);
      }
    }
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when the selected location changes
  useEffect(() => {
    // and more specifically when we
    // load the page OR we change from a geolocate_me location to a classical
    // one, we re-set the selected country
    if (selectedLocation && selectedLocation instanceof Location) {
      updateSelectedCountry(selectedLocation.extract_country_slug());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return (
    <>
      <Row className="country_input">
        <Col>
          <NavDropdown
            title={
              selectedCountrySlug && countries
                ? `${countriesFlags[selectedCountrySlug] as string} \u00A0\u00A0 ${
                    countries[selectedCountrySlug]["real_name"]
                  }`
                : ""
            }
            id="basic-nav-dropdown"
            className="country_selector"
            disabled={geolocateMeActivated}
          >
            {countries &&
              Object.entries(countries).map(([key, value], index) => (
                <NavDropdown.Item
                  key={index}
                  onSelect={() => {
                    updateSelectedCountry(key);
                  }}
                  className="input-country"
                >
                  {`${countriesFlags[key] as string}\u00A0\u00A0 ${t(value["real_name"])}`}
                </NavDropdown.Item>
              ))}
          </NavDropdown>
        </Col>
      </Row>

      <Row className="city_input">
        <Col>
          <div style={{ position: "relative" }}>
            <span className="icon_location">📍</span>
            <div className="card_chevron_AutoSuggest">
              <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            </div>
          </div>
          <CitiesAutoSuggest
            selectedCountrySlug={selectedCountrySlug}
            selectedLocationName={selectedLocation ? selectedLocation.getLocationStr() : ""}
            onSuggestionSelectedCallback={(suggestion) => {
              setSelectedLocation(suggestion);
            }}
            disabled={geolocateMeActivated}
          />
        </Col>
      </Row>
    </>
  );
};

export default CountryAndCitySearch;
