import { FC } from "react";

import { useTranslation } from "next-i18next";

import { loadTranslations } from "@lib/i18n";

const Footer: FC = () => {
  const { t } = useTranslation("jobboard_footer");

  loadTranslations("jobboard_footer");

  return (
    <>
      <div id="footer">
        &copy; 2021 Work4 — <br />
        <a href={t("privacy_policy_url")}>{t("privacy_policy")}</a> —{" "}
        <a href={t("cookies_policy_url")}>{t("cookies")}</a> — <a href={t("terms_of_use_url")}>{t("terms_of_use")}</a>
      </div>
    </>
  );
};

export default Footer;
