import { FC, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";
import { Button, Container, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { loadTranslations } from "@lib/i18n";

import { GeolocateMe, JobSearchFormData, Location } from "@entities/location";

import { yupResolver } from "@hookform/resolvers/yup";

import CountryAndCitySearch from "./country_and_city_search";
import FormCheckGeolocateMe from "./form_check_geolocate_me";
import Title from "./title";

type Props = {
  formData: NodeJS.Dict<string>;
  onFormSubmit: (data: JobSearchFormData) => void;
  initialLocation: Location | GeolocateMe;
  onCountryChanged?: (selectedCountrySlug: string) => void;
};
/**
 * A component that handle the form to search for jobs.
 * It handles both the research of locations and a form to have a keywords input.
 *
 * Upon validation, we call the `onFormSubmit` callback function, to
 * give us the opportunity to either redirect to the search results page
 * or to update the job list with the new job search.
 */
const JobSearchForm: FC<Props> = ({ formData, onFormSubmit, initialLocation, onCountryChanged }) => {
  const { t } = useTranslation("homepage_components");
  loadTranslations("homepage_components");

  // selectedLocation is location that the user select in CountryAndCitySearch
  // we change the url when it changes
  const [selectedLocation, setSelectedLocation] = useState<Location | GeolocateMe>(initialLocation);
  const [geolocateMeActivated, setGeolocateMeActivated] = useState<boolean>(false);

  // necessary because initialLocation is set asynchronously.
  useEffect(() => {
    setSelectedLocation(initialLocation);
  }, [initialLocation]);

  const methods = useForm({
    resolver: yupResolver(
      yup.object({
        search: yup.string(),
        location: yup.string().when("geolocateMe", { is: false, then: yup.string().required() }),
        geolocateMe: yup.boolean().default(initialLocation instanceof GeolocateMe),
      })
    ),
    defaultValues: formData,
  });
  const { handleSubmit, register, setError } = methods;

  return (
    <Container>
      <Title translation_key="homepage_components:search_title" parent="h1" />

      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit((data) => {
            if (selectedLocation === null) {
              // since selectedLocation is currently disconnected from the
              // input/form value, we have to do this validation, for the case
              // where the user input letters in the location field but
              // doesn't select any.
              setError("location", {});
              return;
            }

            // validate the form, will redirect to the correct page.
            onFormSubmit(new JobSearchFormData(data["search"], selectedLocation));
          })}
          className="search-form"
        >
          <Form.Group className="mb-3 search-form-question" controlId="formKeywordSearch">
            <span className="icon">🔍</span>
            <div>
              <Form.Label className="p-2">{t("what")}</Form.Label>
              <Form.Control
                type="search"
                placeholder={`${t("keywords")}`}
                {...register(`search`)}
                className="input-form"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formKeywordSearch">
            <div className="locate-me">
              <Form.Label className="p-2">{t("where")}</Form.Label>
              <Form.Group>
                <FormCheckGeolocateMe
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  geolocateMeActivated={geolocateMeActivated}
                  setGeolocateMeActivated={setGeolocateMeActivated}
                />
              </Form.Group>
            </div>
            <CountryAndCitySearch
              onCountryChanged={onCountryChanged}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              geolocateMeActivated={geolocateMeActivated}
            />
          </Form.Group>
          <Row className="button-discover">
            <Button variant="flat" type="submit">
              {t("homepage_components:homepage_search_button")}
            </Button>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default JobSearchForm;
